import Alpine from 'alpinejs';

// Calibration Image Generator Component
export default function calibrationImageGenerator(initialLasers = []) {
    return {
        lasers: initialLasers,
        selectedLaser: null,
        parameters: [],
        canvas: null,
        previewCanvas: null,
        ctx: null,
        previewCtx: null,
        displayDPI: 96,
        baseScale: 1,
        error: null,
        formData: {
            laser_id: '',
            parameter_id: '',
            sample_size: 10,
            dpi: 400,
            num_steps: 16,
            spacing: 0,
            max_width: 100,
            max_height: 100
        },

        init() {
            this.previewCanvas = this.$refs.previewCanvas;
            this.previewCtx = this.previewCanvas.getContext('2d');
            this.canvas = document.createElement('canvas');
            this.ctx = this.canvas.getContext('2d');
            this.calculateDisplayDPI();
            this.$watch('formData', () => {
                this.updatePreview();
                Alpine.store('calibrationImage').formData = { ...this.formData };
            }, { deep: true });
            this.updatePreview();
        },

        async savePreset() {
            if (!this.formData.parameter_id) {
                alert('Please select a parameter set to save these settings to.');
                return;
            }

            try {
                const response = await fetch(`/parameters/${this.formData.parameter_id}/calibration-settings`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        dpi: parseInt(this.formData.dpi),
                        sample_size: parseFloat(this.formData.sample_size),
                        spacing: parseFloat(this.formData.spacing),
                        max_width: parseFloat(this.formData.max_width),
                        max_height: parseFloat(this.formData.max_height),
                        num_steps: parseInt(this.formData.num_steps)
                    })
                });

                const data = await response.json();

                if (!response.ok) {
                    if (response.status === 401) {
                        window.location.href = '/login';
                        return;
                    }
                    
                    throw new Error(data.error || data.message || 'Failed to save settings');
                }

                // Update the parameter in the list with the new values
                const parameterIndex = this.parameters.findIndex(p => p.id === parseInt(this.formData.parameter_id));
                if (parameterIndex !== -1) {
                    this.parameters[parameterIndex] = {
                        ...this.parameters[parameterIndex],
                        dpi: parseInt(this.formData.dpi),
                        sample_size: parseFloat(this.formData.sample_size),
                        spacing: parseFloat(this.formData.spacing),
                        max_width: parseFloat(this.formData.max_width),
                        max_height: parseFloat(this.formData.max_height),
                        num_steps: parseInt(this.formData.num_steps)
                    };
                }

                alert('Settings saved successfully to parameter set.');
            } catch (error) {
                console.error('Error saving settings:', error);
                alert(error.message || 'Failed to save settings. Please try again.');
            }
        },

        onLaserChange() {
            if (!this.formData.laser_id) {
                this.parameters = [];
                return;
            }

            const laser = this.lasers.find(l => l.id === parseInt(this.formData.laser_id));
            if (laser) {
                this.parameters = laser.parameters || [];
            }
        },

        onParameterChange() {
            if (!this.formData.parameter_id) return;

            const parameter = this.parameters.find(p => p.id === parseInt(this.formData.parameter_id));
            if (parameter) {
                // Update form data with parameter values
                this.formData = {
                    ...this.formData,
                    dpi: parameter.dpi || this.formData.dpi,
                    sample_size: parameter.sample_size || this.formData.sample_size,
                    spacing: parameter.spacing || this.formData.spacing,
                    max_width: parameter.max_width || this.formData.max_width,
                    max_height: parameter.max_height || this.formData.max_height,
                    num_steps: parameter.num_steps || this.formData.num_steps
                };
            }
        },

        calculateDisplayDPI() {
            const div = document.createElement('div');
            div.style.width = '1in';
            div.style.height = '1in';
            div.style.position = 'absolute';
            div.style.left = '-100%';
            div.style.top = 0;
            div.style.visibility = 'hidden';
            document.body.appendChild(div);
            
            this.displayDPI = div.offsetWidth;
            document.body.removeChild(div);
            
            this.baseScale = this.displayDPI / 96;
            
            // Remove existing listeners if any
            if (this.resizeHandler) {
                window.removeEventListener('resize', this.resizeHandler);
                window.removeEventListener('orientationchange', this.resizeHandler);
            }

            // Create debounced handler
            let resizeTimeout;
            this.resizeHandler = () => {
                if (resizeTimeout) {
                    clearTimeout(resizeTimeout);
                }
                resizeTimeout = setTimeout(() => {
                    const oldDPI = this.displayDPI;
                    const oldScale = this.baseScale;
                    
                    const div = document.createElement('div');
                    div.style.width = '1in';
                    div.style.height = '1in';
                    div.style.position = 'absolute';
                    div.style.left = '-100%';
                    div.style.top = 0;
                    div.style.visibility = 'hidden';
                    document.body.appendChild(div);
                    
                    this.displayDPI = div.offsetWidth;
                    document.body.removeChild(div);
                    
                    this.baseScale = this.displayDPI / 96;

                    // Only update if the DPI or scale actually changed
                    if (oldDPI !== this.displayDPI || oldScale !== this.baseScale) {
                        this.updatePreview();
                    }
                }, 150); // 150ms debounce delay
            };
            
            window.addEventListener('resize', this.resizeHandler);
            window.addEventListener('orientationchange', this.resizeHandler);
        },

        calculateMaxSampleSize() {
            const maxWidthMm = this.formData.max_width;
            const maxHeightMm = this.formData.max_height;
            const numSteps = this.formData.num_steps;
            const spacingMm = this.formData.spacing;

            const samplesPerRow = Math.floor(Math.sqrt(numSteps));
            const rowsNeeded = Math.ceil(numSteps / samplesPerRow);
            
            const availableWidthMm = maxWidthMm - (spacingMm * (samplesPerRow + 1));
            const availableHeightMm = maxHeightMm - (spacingMm * (rowsNeeded + 1));

            const exactWidthPerSample = availableWidthMm / samplesPerRow;
            const exactHeightPerSample = availableHeightMm / rowsNeeded;
            
            const exactSize = Math.min(exactWidthPerSample, exactHeightPerSample);
            
            const isExactDivision = spacingMm === 0 && 
                (Math.abs(exactSize - Math.round(exactSize)) < 0.0001);
            
            if (isExactDivision) {
                this.formData.sample_size = Math.round(exactSize);
            } else {
                this.formData.sample_size = Math.max(1, Math.floor(exactSize * 10) / 10);
            }

            const testLayout = this.calculateLayout(true);
            if (testLayout === null) {
                this.formData.sample_size = Math.max(1, this.formData.sample_size - 0.1);
            }
        },

        calculateLayout(forDownload = false) {
            const dpi = forDownload ? this.formData.dpi : 300;
            const sampleSizePx = Math.round((this.formData.sample_size / 25.4) * dpi);
            const spacingPx = Math.round((this.formData.spacing / 25.4) * dpi);
            const maxWidthPx = Math.round((this.formData.max_width / 25.4) * dpi);
            const maxHeightPx = Math.round((this.formData.max_height / 25.4) * dpi);
            const totalPatches = this.formData.num_steps;

            const patchWidth = sampleSizePx + spacingPx;
            const maxPatchesPerRow = Math.floor((maxWidthPx - spacingPx) / patchWidth);

            if (maxPatchesPerRow === 0) {
                this.error = 'Sample size is too large for the maximum width';
                return null;
            }

            let rows = [];
            let remainingPatches = totalPatches;
            let currentRow = 0;
            let totalHeight = spacingPx;

            while (remainingPatches > 0) {
                const patchesInThisRow = Math.min(remainingPatches, maxPatchesPerRow);
                const rowWidth = spacingPx + patchesInThisRow * patchWidth;
                const rowHeight = spacingPx + sampleSizePx;

                if (totalHeight + rowHeight > maxHeightPx) {
                    this.error = `Cannot fit ${totalPatches} samples of ${this.formData.sample_size}mm with ${this.formData.spacing}mm spacing within ${this.formData.max_width}mm × ${this.formData.max_height}mm`;
                    return null;
                }

                rows.push({
                    patches: patchesInThisRow,
                    width: rowWidth,
                    startIndex: totalPatches - remainingPatches
                });

                remainingPatches -= patchesInThisRow;
                totalHeight += rowHeight;
                currentRow++;
            }

            this.error = null;
            return {
                rows,
                width: Math.max(...rows.map(r => r.width)),
                height: totalHeight,
                sampleSizePx,
                spacingPx
            };
        },

        updatePreview() {
            const layout = this.calculateLayout(false);
            if (!layout) return;

            const { width, height, rows, sampleSizePx, spacingPx } = layout;
            
            this.displayScale = this.baseScale * (96 / 300);
            
            const scaledWidth = Math.round(width * this.displayScale);
            const scaledHeight = Math.round(height * this.displayScale);
            
            this.previewCanvas.style.width = `${scaledWidth}px`;
            this.previewCanvas.style.height = `${scaledHeight}px`;
            this.previewCanvas.width = width;
            this.previewCanvas.height = height;
            
            this.previewCtx.imageSmoothingEnabled = true;
            this.previewCtx.imageSmoothingQuality = 'high';
            
            this.canvas.width = width;
            this.canvas.height = height;

            [this.previewCtx, this.ctx].forEach(ctx => {
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, width, height);

                let y = spacingPx;
                rows.forEach(row => {
                    for (let i = 0; i < row.patches; i++) {
                        const patchIndex = row.startIndex + i;
                        const grayValue = Math.round((patchIndex / (this.formData.num_steps - 1)) * 255);
                        const x = spacingPx + i * (sampleSizePx + spacingPx);
                        
                        if (grayValue > 127) {
                            ctx.fillStyle = 'black';
                            ctx.fillRect(x, y, sampleSizePx, sampleSizePx);
                            ctx.fillStyle = `rgb(${grayValue}, ${grayValue}, ${grayValue})`;
                            ctx.fillRect(x + 2, y + 2, sampleSizePx - 4, sampleSizePx - 4);
                        } else {
                            ctx.fillStyle = `rgb(${grayValue}, ${grayValue}, ${grayValue})`;
                            ctx.fillRect(x, y, sampleSizePx, sampleSizePx);
                        }
                    }
                    y += sampleSizePx + spacingPx;
                });
            });
        },

        downloadImage() {
            if (this.error) {
                alert('Please fix the errors before downloading.');
                return;
            }

            // Generate high-resolution image
            const layout = this.calculateLayout(true);
            if (!layout) {
                alert('Failed to calculate layout for download');
                return;
            }

            // Set up the canvas for full resolution
            this.canvas.width = layout.width;
            this.canvas.height = layout.height;
            this.ctx.fillStyle = 'white';
            this.ctx.fillRect(0, 0, layout.width, layout.height);

            // Draw the patches at full resolution
            let y = layout.spacingPx;
            layout.rows.forEach(row => {
                for (let i = 0; i < row.patches; i++) {
                    const patchIndex = row.startIndex + i;
                    const grayValue = Math.round((patchIndex / (this.formData.num_steps - 1)) * 255);
                    const x = layout.spacingPx + i * (layout.sampleSizePx + layout.spacingPx);
                    
                    if (grayValue > 127) {
                        this.ctx.fillStyle = 'black';
                        this.ctx.fillRect(x, y, layout.sampleSizePx, layout.sampleSizePx);
                        this.ctx.fillStyle = `rgb(${grayValue}, ${grayValue}, ${grayValue})`;
                        this.ctx.fillRect(x + 2, y + 2, layout.sampleSizePx - 4, layout.sampleSizePx - 4);
                    } else {
                        this.ctx.fillStyle = `rgb(${grayValue}, ${grayValue}, ${grayValue})`;
                        this.ctx.fillRect(x, y, layout.sampleSizePx, layout.sampleSizePx);
                    }
                }
                y += layout.sampleSizePx + layout.spacingPx;
            });

            // Add metadata text
            const text = `Steps: ${this.formData.num_steps} | ${this.formData.dpi} DPI | Generated: ${new Date().toISOString().split('T')[0]}`;
            this.ctx.font = '10px Arial';
            this.ctx.fillStyle = 'black';
            this.ctx.textAlign = 'center';
            this.ctx.textBaseline = 'bottom';
            this.ctx.fillText(text, layout.width / 2, layout.height - 20);

            // Create download link
            const link = document.createElement('a');
            link.download = `calibration-${new Date().toISOString().split('T')[0]}.png`;
            link.href = this.canvas.toDataURL('image/png');
            link.click();
        }
    };
} 